import axios from "axios";

export default ({getState,dispatch}) => next => action => {
    if (action.type === "EC2TABLE:LOAD-DATA") {
        axios.get("/" + getState().organizations.selectedOrg + "/ec2-instances")
            .then(
                ({data}) => {
                    dispatch({
                        type:"EC2TABLE:LOAD-DATA-SUCCESS",
                        payload:data
                    });
                }
            )
            .catch((err) => {
                dispatch({
                type:"EC2TABLE:LOAD-DATA-FAILURE",
                    payload:err
                });
                }
            )
    }

    return next(action);
}