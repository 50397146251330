import axios from "axios";

export default ({getState,dispatch}) => next => action => {
    if (action.type === "TABLEREGIONS:LOAD-DATA") {
        axios.get("/" + getState().organizations.selectedOrg + "/regions-cost-table")
            .then(
                ({data}) => {
                    dispatch({
                        type:"TABLEREGIONS:LOAD-DATA-SUCCESS",
                        payload:data
                    });
                }
            )
            .catch((err) => {
                    dispatch({
                        type:"TABLEREGIONS:LOAD-DATA-FAILURE",
                        payload:err
                    });
                }
            )
    }

    return next(action);
}