import _ from "lodash";

const toggleClass = (element, className) => {
    if(element.classList.contains(className)){
        element.classList.remove(className)
    }
    else {
        element.classList.add(className)
    }
}

export const onClickRow = (state, rowInfo, column, instance) => { return {
    onClick: (e) => {
        let selection = window.getSelection();
        if(selection.type === "Range" && selection.toString().trim()) {
            return;
        }
        const rowElement = e.currentTarget.closest(".rt-tr-group");
        rowElement && toggleClass(rowElement,"max-height-none");

        const { expanded } = state;
        const path = rowInfo.nestingPath[0];
        const diff = { [path]: expanded[path] ? false : true };

        instance.setState({
            expanded: {
                ...expanded,
                ...diff
            }
        });
    }
}};

export const multiFilterMethod = (filter, row) => _.get(filter.value,'length') > 0 ? _.some(filter.value, ["value", row[filter.id]]) : true;
export const filterMethod = (filter, row) => {
    if(!filter.value){
        return true;
    }
    return row[filter.id] === filter.value.value};

export const calculateSum = (filters, data) => {
    const filteredData = _.filter(data.data,(row) => _.some(filters.value,row.Account));
    let ans = {Account:'Sum'};
    data.labels.forEach((key) => ans[key] = _.sum(_.map(filteredData,key)));
    return ans
};

export const configComplianceMap = {
    "COMPLIANT":"Compliant",
    "NON_COMPLIANT":"Non Compliant",
    "NOT_APPLICABLE":"Not Applicable",
    "INSUFFICIENT_DATA":"Insufficient Data",
};