const _ = require('lodash');

/*eslint default-case: 0*/

const defaultState = () => ({authenticating: true});

const extractFirstName = (fullName) => (fullName || "").trim().split(" ")[0];

export default (state = defaultState(), {payload, type}) => {
    switch (type){
        case "AUTH:REFRESH-SSO-SUCCESS": {
            const firstName = extractFirstName(payload.idTokenPayload["http://cloud.netapp.com/user_metadata"].name);
            const email = _.get(payload, ["idTokenPayload", "http://cloud.netapp.com/user_metadata", "email"]);
            const scopeArr = (payload.scope || "").split(" ");
            const userId = _.get(payload, "idTokenPayload.sub");

            return {
                userId,
                accessToken: payload.accessToken,
                authenticated: true,
                data: payload.idTokenPayload,
                firstName,
                scopes: _(scopeArr).keyBy().mapValues(_.constant(true)).value(),
                email
            };
        }
        case "AUTH:REFRESH-SSO-FAILED": {
            return { authenticated: false};
        }
        case "AUTH:LOGIN": {
            return { loggingIn: true }
        }
    }
    return state;
};
