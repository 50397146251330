import React from 'react'
import {connect} from "react-redux";
import styles from './components.module.scss'
import "./TableStyle.scss"
import ReactTable from "react-table";
import CellWrapper from "./react-table-components/CellWrapper";
import FooterWrapper from "./react-table-components/FooterWrapper";
import HeaderWrapper from '../components/react-table-components/HeaderWrapper'
import Loader from '../components/react-table-components/Loader'
import _ from "lodash";
import DropDownFilter from "./react-table-components/DropDownFilter";
import {onClickRow, filterMethod} from "../utils/utils";
import TableHeader from "./TableHeader";

const SubTable = ({row,tableData,subColumns}) => {
    const dataRow = tableData.data.find(dataRow => dataRow.Service === row.original.Service).MoreInfo;
    if(!dataRow)
        return;
    const subColumnsConst = {
        headerStyle: {
            overflow: "visible"
        },
        Header: "Subscription",
        accessor: "Subscription",
        width:250,
        Cell: cell => CellWrapper(cell.value),
        Filter: ({ filter, onChange }) =>
            DropDownFilter({data:dataRow, fieldName:'Subscription', filter, onChange }),
        filterMethod: filterMethod
    };
    return (
        <div style={{ padding:"25px 0 15px 15px",marginLeft:"35px",marginBottom:"35px",overflow:"hidden" }}>
            <ReactTable
                defaultPageSize={dataRow.length}
                data= {dataRow}
                columns={[subColumnsConst,...subColumns.map(col=>{return{Cell:col.Cell,Header:col.Header,accessor:col.accessor,filterable:col.filterable,minWidth:50,maxWidth: 69,style: col.style}})]}
                showPagination = {false}
                showPageSizeOptions = {false}
                filterable = {true}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                minRows = {0}
                style={{minHeight:500}}
            />
        </div>
    )
};

const CostTableRegionsAzure = ({getTableData,isTableLoaded,tableData,summeryRow,error,selectedOrg}) => {
    getTableData(isTableLoaded);
    if(isTableLoaded) {
        if(error){
            return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        const mainColumns=[
            {
                Header:"Regions",
                headerClassName: styles['maintenance-table-header'],
                columns: []
            }
        ];
        const constColumns = [
            {
                expander: true,
            },
            {
                Header: "Services",
                accessor: "Service",
                width:210,
                Cell: cell => CellWrapper(cell.value),
                Footer: FooterWrapper(summeryRow["Service"])
            },
            {
                Header: "Total",
                accessor: "Total",
                maxWidth: 70,
                filterable:false,
                Cell: cell => CellWrapper(cell.value),
                Footer: FooterWrapper(summeryRow["Total"])
            }
        ];
        const subColumns = tableData.labels.map((label) => {return{
            Cell:(cell) => {
                if(!cell.row[label])
                    return 0;
                return CellWrapper(cell.row[label]);
            },
            Header:HeaderWrapper(label),
            Footer: FooterWrapper(summeryRow[label]),
            accessor:label,
            maxWidth:80,
            minWidth:50,
            filterable:false,
            style: {overflow:"hidden"}
        }});
        mainColumns[0].columns = constColumns.concat(subColumns);
        return <div>
            <TableHeader headerTitle={"Services cost"}/>
            <ReactTable
                data={tableData.data}
                columns={mainColumns}
                defaultPageSize={tableData.data.length}
                minRows = {0}
                loading={!isTableLoaded}
                sortable = {true}
                filterable = {true}
                className={"-striped -highlight " + styles["maintenance-table"]}
                showPageSizeOptions = {false}
                showPagination = {false}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                getTdProps={onClickRow}
                SubComponent={row => SubTable({row:row,tableData:tableData,subColumns:subColumns})}
            />
            <span style={{paddingLeft:"40px"}}>*Not including unassigned charges</span>
        </div>
    } else {
        return <Loader/>
    }

};

const mapStateToProps = state => {
    return {
        isTableLoaded: state.costTableRegionsAzure.isTableLoaded,
        tableData: state.costTableRegionsAzure.tableData,
        summeryRow: state.costTableRegionsAzure.summeryRow,
        error: state.costTableRegionsAzure.error,
        selectedOrg: state.organizations.selectedOrg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTableData:(isTableLoaded)=> {
            if(!isTableLoaded)
                setTimeout(() => {
                    dispatch({type: 'TABLEREGIONSAZURE:LOAD-DATA'});
                })
        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(CostTableRegionsAzure);