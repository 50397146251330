export default (state = () => {}, {payload, type}) => {
    switch (type){
        case "CHART:RENDER-CHART-SUCCESS": {
            return {...state,isChartLoaded:true,error:false,chartData:payload}
        }
        case "CHART:RENDER-CHART-FAILURE": {
            return {...state,isChartLoaded:true,error:payload.response.data.message||true}
        }
        case "ORGANIZATIONS:CHANGEORG": {
                    return {...state,isChartLoaded:false}
                }
        default:
            break;
    }
    return state;
}