import React from 'react'
import {connect} from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import styles from './components.module.scss'
import "./TableStyle.scss"
import DropDownFilter from './react-table-components/DropDownFilter'
import classNames from 'classnames';
import CellWrapper from '../components/react-table-components/CellWrapper'
import FooterWrapper from '../components/react-table-components/FooterWrapper'
import HeaderWrapper from '../components/react-table-components/HeaderWrapper'
import Loader from '../components/react-table-components/Loader'
import {onClickRow, multiFilterMethod, calculateSum} from '../utils/utils'
import _ from "lodash";
import TableHeader from "./TableHeader";


const SubTable = ({row,tableData,subColumns}) => {
    const dataRow = tableData.data.find(dataRow => dataRow.Account === row.original.Account).MoreInfo;
    const subColumnsConst = {
        width:200,
        Header: "Service",
        accessor: "service",
        Cell: cell => CellWrapper(cell.value)
    };
    return (
        <div style={{ padding:"15px 0 15px 15px",maxWidth:"92.7%",marginLeft:"20px",marginBottom:"35px" }}>
            <ReactTable
                data= {dataRow}
                columns={[subColumnsConst,...subColumns.map(col=>{return{Cell:col.Cell,Header:col.Header,accessor:col.accessor,filterable:col.filterable,minWidth:50,maxWidth: 94,style: col.style}})]}
                defaultPageSize={dataRow.length}
                showPagination = {false}
                showPageSizeOptions = {false}
                filterable = {true}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                minRows = {0}
            />
        </div>
    )
};


const CostTableAccounts = ({getTableData,isTableLoaded,tableData,summeryRow,error,selectedOrg}) => {
    getTableData(isTableLoaded);
    if(isTableLoaded) {
        if(error){
            return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        if(tableData.data.length < 2){
            return <span>No data to display</span>
        }
        const constColumns = [
            {
                expander: true,
            },
            {
                headerStyle: {
                    overflow: "visible"
                },
                width: 300,
                Header: "Account",
                accessor: "Account",
                Cell: (cell) => CellWrapper(cell.value),
                Footer: FooterWrapper(summeryRow["Account"]),
                Filter: ({ filter, onChange }) => {
                    console.log(tableData);
                    console.log(filter);
                    filter && console.log(calculateSum(filter, tableData));
                    return DropDownFilter({
                    data: tableData.data,
                    fieldName: 'Account',
                    width:1635,
                    onChange,
                    isMulti:true
                })},
                filterMethod: multiFilterMethod
            }
        ];
        const lastColumns = [
            {
                Header: "Total",
                accessor: "Total",
                filterable:false,
                Cell: cell => CellWrapper(cell.value),
                Footer: FooterWrapper(summeryRow["Total"])
            }
        ];
        const subColumns = tableData.labels.map((label) => {return{
            Cell: (cell) => {
                if(!cell.value)
                    return 0;
                return CellWrapper(cell.value)
            },
            Header:HeaderWrapper(label),
            Footer: FooterWrapper(summeryRow[label]),
            accessor:label,
            filterable:false,
            minWidth:60,
            maxWidth: 95
        }});
        const mainColumns=[
            {
                Header:"Months",
                headerClassName: styles['maintenance-table-header'],
                columns: constColumns.concat(subColumns,lastColumns)
            }
        ];
        return <div>
            <TableHeader headerTitle={"Accounts cost"}/>
            <ReactTable
                data={tableData.data}
                columns={mainColumns}
                defaultPageSize={tableData.data.length}
                minRows = {0}
                loading={!isTableLoaded}
                sortable = {true}
                filterable = {true}
                className={classNames("-striped", "-highlight", styles["maintenance-table"])}
                showPageSizeOptions = {false}
                showPagination = {false}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                getTdProps={onClickRow}
                SubComponent={row => SubTable({row:row,tableData:tableData,subColumns:subColumns})}
                style={{minHeight:500}}/>
        </div>
    } else {
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isTableLoaded: state.costTableAccounts.isTableLoaded,
        tableData: state.costTableAccounts.tableData,
        summeryRow: state.costTableAccounts.summeryRow,
        error: state.costTableAccounts.error,
        selectedOrg: state.organizations.selectedOrg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTableData: (isTableLoaded) => {
            if(!isTableLoaded) {
                setTimeout(() => {
                    dispatch({type: 'TABLEACCOUNTS:LOAD-DATA'});
                });
            }
        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(CostTableAccounts);