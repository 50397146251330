import React from 'react'
import _ from 'lodash';
import Select from 'react-select';
export default ({ data,fieldName, onChange, width,isMulti = false, labelMap}) => {
    let fieldStyleControl = width ? {
        position: 'absolute',
        width: width+'px',
        zIndex: 2
    }:{};
    let fieldStyleMenu = width ? {
        marginTop:'28px'
    }:{};
    return (
        <Select
            isClearable={true}
            isMulti={isMulti}
            onChange={option => onChange(option)}
            options={_(data).map(fieldName).uniq().map(value => ({value:value,label:labelMap?labelMap[value]:value})).value()}
            styles={{control: styles => ({ ...styles, ...fieldStyleControl}),menuList:styles => ({ ...styles, ...fieldStyleMenu})}}
        />
    )
}