

export default (state = {orgsLoaded:false,refreshData:false}, {payload, type,orgId,selectedOrg}) => {
    switch (type){
        case "ORGANIZATIONS:GETORGS-SUCCESS": {
            return {...state,orgs:payload,orgsLoaded:true,selectedOrg:selectedOrg||payload[0].orgId}
        }
        case "ORGANIZATIONS:REFRESH": {
            return {...state,refreshData:true}
        }
        case "ORGANIZATIONS:REFRESH-SUCCESS": {
            return {...state,refreshData:false}
        }
        case "ORGANIZATIONS:REFRESH-FAILURE": {
            return {...state,refreshData:false}
        }
        case "ORGANIZATIONS:GETORGS-FAILURE": {
            return {...state,orgsLoaded:true,error:payload.response.data.message||true}
        }
        case "ORGANIZATIONS:CHANGEORG": {
            return {...state,selectedOrg:orgId,OrgChanged:true}
        }
        default:
            break;
    }
    return state;
}
