import axios from "axios";

export default ({getState,dispatch}) => next => action => {
    if (action.type === "ORGANIZATIONS:GETORGS") {
        axios.get("/user-organizations")
                    .then(
                        ({data}) => {
                            const org = window.sessionStorage.getItem("org");
                            dispatch({
                                type:"ORGANIZATIONS:GETORGS-SUCCESS",
                                payload:data,
                                selectedOrg:org
                            });
                        }
                    )
                    .catch((err) => {
                        dispatch({
                            type:"ORGANIZATIONS:GETORGS-FAILURE",
                            payload:err
                        });
                }
            )
    }
    else if (action.type === "ORGANIZATIONS:REFRESH") {
        axios.post("/" + getState().organizations.selectedOrg + "/refresh-organization")
            .then(()=>{
                dispatch({
                    type:"ORGANIZATIONS:REFRESH-SUCCESS"
                });
        })
            .catch(()=>{
                dispatch({
                    type:"ORGANIZATIONS:REFRESH-FAILURE"
                });
            })
    }

    return next(action);
}
