import axios from "axios";
import {history} from "./../store";
import querystring from "query-string";
import auth0 from 'auth0-js';

const auth = new auth0.WebAuth({
    clientID: window.__auth__.clientId,
    domain: window.__auth__.domain,
    audience: window.__auth__.audience,
    redirectUri: window.location.origin,
    responseType: 'token id_token',
    scope: 'openid profile cc:internal cc:maintenance',
    leeway: 30
});

export default ({dispatch}) => next => action => {
    if (action.type === "AUTH:REFRESH-SSO") {
        const loginSuccess = (authResult) => {
            console.log(authResult);
            axios.defaults.headers.common['Authorization'] = `Bearer ${authResult.accessToken}`;
            axios.defaults.baseURL = process.env.REACT_APP_MAINTENANCE_BACKEND_BASE_URL;

            dispatch({
                type: "AUTH:REFRESH-SSO-SUCCESS",
                payload: authResult
            });

            setTimeout(() => {
                dispatch({
                    type: "AUTH:REFRESH-SSO",
                });
            }, authResult.expiresIn * 1000 - 10000);

            if(authResult.appState && authResult.appState.pathname){
                const path = authResult.appState.pathname.startsWith('/') ? authResult.appState.pathname : '/' + authResult.appState.pathname;
                history.replace(path);
            } else {
                const search = querystring.parse(window.location.search);
                delete search.redirectToLogin;
                delete search.paramsForLoginPage;
                const searchString = querystring.stringify(search);
                history.replace(window.location.pathname + (searchString ? "?" + searchString : ''));
            }
        };

        const loginFail = (() => {
            dispatch({
                type: "AUTH:REFRESH-SSO-FAILED"
            });
            history.replace(window.location.pathname + window.location.search);
        });

        if(window.location.hash && (window.location.hash.indexOf("access_token") > 0 || window.location.hash.indexOf("error") > 0)){
            auth.parseHash((err, authResult) => {
                if(err){
                    loginFail(err);
                } else {
                    loginSuccess(authResult);
                }
            });
        } else {
            auth.checkSession({}, (err, authResult) => {
               if(err){
                   loginFail(err);
               } else {
                   loginSuccess(authResult);
               }
            });
        }

    } else if (action.type === "AUTH:LOGOUT") {
        auth.logout({returnTo: window.location.origin, federated: true});
    } else if (action.type === "AUTH:LOGIN") {
        auth.authorize({
            redirectUri: window.location.origin,
            appState: {
                pathname: window.location.pathname + window.location.search
            }
        });
    }
    return next(action);
}