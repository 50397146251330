import FixedLeftNav from "ui-components-rollup/dist/FixedLeftNav";
import {ReactComponent as SecurityIcon} from "ui-components-rollup/src/images/nav-icons/ic-security.svg";
import {ReactComponent as ComputingIcon} from "ui-components-rollup/src/images/nav-icons/ic-computing.svg";
import {ReactComponent as MoneyIcon} from "ui-components-rollup/src/images/nav-icons/ic-money.svg";
import {ReactComponent as ArrowIcon} from "ui-components-rollup/src/images/monochrome-icons/ic-menu-arrow.svg";
import {NavLink} from "react-router-dom";
import {ReactComponent as NetAppLogo} from "ui-components-rollup/src/images/netapp-services/netapp-header-logo-blue.svg";
import React from "react";

const HeaderButton = () => <NavLink to="/"><NetAppLogo/></NavLink>;

const securityLinks = [
    {href: "/guard-duty", title: "Guard Duty"},
    {href: "/config-service", title: "Config service"},
    {href: "/azure-advisor", title: "Azure advisor", label: "beta"}
];

const costLinks = [
    {href: "/cost-chart", title: "Cost per Month"},
    {href: "/cost-map", title: "Cost by Region Map"},
    {href: "/cost-table-regions", title: "Service per Region"},
    {href: "/cost-table-accounts", title: "Account per Month"},
    {href: "/cost-table-regions-azure", title: "Service per Region azure", label: "beta"}
];

const mainLinks = [
    {isDropdown: true, title: "Security", Icon: SecurityIcon, links: securityLinks},
    {isDropdown: true, title: "Cost", Icon: MoneyIcon, links: costLinks},
    {href: "/ec2-instances-table", title: "KeepAlive Instances", Icon: ComputingIcon}
];

export default () => <FixedLeftNav ArrowIcon={ArrowIcon} HeaderButton={HeaderButton} InternalLink={NavLink} mainLinks={mainLinks}/>;
