import React, {Fragment, useState} from 'react'
import { Bar } from 'react-chartjs-2';
import Loader from '../components/react-table-components/Loader'
import {connect} from "react-redux";
import chartStyles from './CostChart.module.scss'
import _ from "lodash";
import TableHeader from "./TableHeader";

const CostChart = ({getChartData, isChartLoaded, chartData, error,selectedOrg}) => {
    getChartData(isChartLoaded);
    const parent = React.createRef();
    const [width, setWidth] = useState(0);
    if(isChartLoaded){
        if(error){
            return <span>Failed to get chart{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        chartData.options.hover={
            "animationDuration": 0
        };
        chartData.options.animation = {
            "duration": 1,
            "onComplete": function() {
                const chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                let sum = [];
                let xPoint = [];
                let yPoint = [];
                this.data.datasets.forEach(function(dataset, i) {
                    const meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function(bar, index) {
                        if(!sum[index])
                            sum[index] = 0;
                        sum[index] += +dataset.data[index];
                        xPoint[index] = bar._model.x;
                        yPoint[index] = bar._model.y - 5
                    });
                });
                sum.forEach(function(sum, index) {
                    ctx.fillText(sum.toFixed(2), xPoint[index], yPoint[index]);
                });
            }
        };
        setTimeout(() => {
            setWidth(parent.current ? parent.current.clientWidth - 100 : width);
        });
        return <Fragment>
            <TableHeader headerTitle={"Cost chart"}/>
            <div className={chartStyles["chart"]} ref={parent}>
                {width && <Bar id="myChart" style={{margin: "0 auto"}} width={width} height={700} data={chartData.data} options={chartData.options}/>}
            </div>
        </Fragment>
    }else{
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isChartLoaded: state.costChart.isChartLoaded,
        chartData: state.costChart.chartData,
        error: state.costChart.error,
        selectedOrg: state.organizations.selectedOrg
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getChartData: (isChartLoaded) => {
            if(!isChartLoaded){
                setTimeout(() => {
                    dispatch({type: 'CHART:LOAD-DATA'});
                })
            }

        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(CostChart);
