import React from 'react';
import {connect} from 'react-redux'
import {Route, Switch, Redirect} from 'react-router-dom';
import appHeaderStyles from './styles/header/AppHeader.module.scss';
import appStyles from "./styles/app/app.module.scss";
import headerStyles from "./header.module.scss";
import buttons from "./styles/button/button.module.scss"
import classNames from "classnames";
import CostChart from './components/CostChart';
import CostMap from './components/CostMap';
import CostTableRegions from './components/CostTableRegions';
import CostTableAccounts from './components/CostTableAccounts';
import EC2InstancesTable from './components/EC2InstancesTable';
import GuardDutyTable from './components/GuardDutyTable';
import ConfigServiceTable from './components/ConfigServiceTable';
import CostTableRegionsAzure from './components/CostTableRegionsAzure';
import AzureAdvisorTable from './components/AzureAdvisorTable'
import _ from "lodash";
import Select, { components } from 'react-select';
import Loader from './components/react-table-components/Loader'
import MainNav from './components/main-nav/MainNav'
import {ReactComponent as InlineLoader} from "ui-components-rollup/src/images/loaders/inline-loader.svg";
import {ReactComponent as RefreshIcon} from "ui-components-rollup/src/images/monochrome-icons/refresh.svg";

const headerMapDispatchToProps = dispatch => {
    return {
        getOrgs: () => {
            dispatch({ type: "ORGANIZATIONS:GETORGS" });
        },
        changeOrg: (orgId) => {
            window.sessionStorage.setItem("org", orgId);
            dispatch({ type: "ORGANIZATIONS:CHANGEORG", orgId: orgId });
        },
        refresh: () => {
            dispatch({ type: "ORGANIZATIONS:REFRESH" });
        }
    }
};
const headerMapStateToProps = state => {
    return {
        orgs: state.organizations.orgs,
        orgsLoaded: state.organizations.orgsLoaded,
        selectedOrg: state.organizations.selectedOrg,
        refreshData: state.organizations.refreshData
    };
};
const selectButtonStyle = {
    control: () => ({
        borderWidth: 0,
        width: 150,
        textAlign: 'center'
    }),
    option: (provided, state) => ({
        ...provided,
        textAlign:"center"
    }),
    singleValue: () => ({
        color: 'white',
        marginRight: 'auto',
        marginLeft: 'auto'
    }),
    dropdownIndicator: () => ({
        color: 'white',
        position: 'absolute',
        right: 0,
        top: '8px'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    })
};

const MenuList = props => {
    return (
        <components.MenuList {...props}>
            <div style={{marginBottom:'5px',fontSize:'15px',padding:'5px',textAlign:"center"}}>Select organization</div>
            {props.children}
        </components.MenuList>
    );
};

let SelectOptions = [];
const Header = ({ auth, logout, getOrgs, changeOrg, refresh, orgs, orgsLoaded, selectedOrg, refreshData }) => {
    if (!orgsLoaded) {
        getOrgs();
    } else {
        SelectOptions = (orgs || []).map((org) => {
            return { value: org.orgId, label: org.orgName }
        });
    }
    return (
        <header className={classNames(appHeaderStyles.base, headerStyles.base)}>
            <div className={classNames(headerStyles.widgets)}>
                <button className={classNames(headerStyles.logout, buttons.transparent)} onClick={logout}>Log Out
                </button>
                <span className={headerStyles.name}>
                <span className="text-ellipsis"
                      style={{ display: "inline-block" }}>{auth.firstName} | {auth.email}</span>
            </span>
                {refreshData ? <InlineLoader className={classNames("inline-loader", headerStyles["refresh-inline-loader"])}/> :
                    <button title={"Refresh data"} className={classNames(headerStyles.refreshButton)} onClick={refresh}><RefreshIcon/></button>}

                {SelectOptions.length > 0 &&
                <div className={headerStyles.organizationSelect}>
                    <Select defaultValue={SelectOptions.find((elem) => elem.value === selectedOrg) || SelectOptions[0]}
                            options={SelectOptions} onChange={(val) => {
                        changeOrg(val.value)
                    }}
                            styles={selectButtonStyle}
                            components={{ MenuList }}/>
                </div>}
            </div>
        </header>
    )
};
const ConnectedHeader = connect(headerMapStateToProps, headerMapDispatchToProps)(Header);

const App = ({ auth, logout, isOrgsLoaded }) => {
    return <div className={classNames(appStyles.base, appStyles["with-nav-menu"])}>
        <ConnectedHeader auth={auth} logout={logout}/>
        {!auth.scopes["cc:maintenance"] && <div>
            Sorry buddy... no permissions.
        </div>}
        {auth.scopes["cc:maintenance"] && !isOrgsLoaded && <Loader/>}
        {auth.scopes["cc:maintenance"] && isOrgsLoaded && <div>
            <MainNav/>
            <Switch>
                <Route exact path="/" render={() => (<Redirect to="/guard-duty"/>)}/>
                <Route path="/guard-duty" component={GuardDutyTable}/>
                <Route path="/config-service" component={ConfigServiceTable}/>
                <Route path="/cost-chart" component={CostChart}/>
                <Route path="/cost-map" component={CostMap}/>
                <Route path="/cost-table-regions" component={CostTableRegions}/>
                <Route path="/cost-table-accounts" component={CostTableAccounts}/>
                <Route path="/ec2-instances-table" component={EC2InstancesTable}/>
                <Route path="/cost-table-regions-azure" component={CostTableRegionsAzure}/>
                <Route path="/azure-advisor" component={AzureAdvisorTable}/>
            </Switch>
        </div>}
    </div>;
};

const Wrapper = ({ auth, login, logout, isOrgsLoaded }) => {
    if (auth.authenticating) {
        return <div>
            Loading...
        </div>
    } else if (auth.authenticated) {
        return <App auth={auth} logout={logout} isOrgsLoaded={isOrgsLoaded}/>
    } else if (auth.loggingIn) {
        return <div/>
    } else {
        login();
        return <div/>
    }
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        isOrgsLoaded: state.organizations.orgsLoaded
    };
};

const mapDispatchToProps = dispatch => {
    const login = _.once(() => {
        setTimeout(() => {
            dispatch({ type: "AUTH:LOGIN" });
        });
    });

    return {
        logout: () => {
            dispatch({ type: "AUTH:LOGOUT" });
        },
        login
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
