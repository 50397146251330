import React from 'react'
import Loader from '../components/react-table-components/Loader'
import {connect} from "react-redux";
import mapStyles from './CostMap.module.scss'
import _ from "lodash";
import TableHeader from "./TableHeader";

const CostMap = ({getMapData, isMapLoaded, mapData,error,selectedOrg}) => {
    getMapData(isMapLoaded);
    if(isMapLoaded) {
        if(error){
            return <span>Failed to get map{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        return <div>
            <TableHeader headerTitle={"Cost map"}/>
            <img className={mapStyles["map"]} alt="Cost distribution map" src={mapData}/>
        </div>
    } else {
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isMapLoaded: state.costMap.isMapLoaded,
        mapData: state.costMap.mapData,
        error: state.costMap.error,
        selectedOrg: state.organizations.selectedOrg
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMapData: (isMapLoaded)=> {
            if(!isMapLoaded){
                setTimeout(() => {
                    dispatch({type:'MAP:LOAD-DATA'});
                })
            }
        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(CostMap);