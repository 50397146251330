import React from 'react'
import {connect} from "react-redux";
import styles from './components.module.scss'
import "./TableStyle.scss"
import ReactTable from "react-table";
import DropDownFilter from './react-table-components/DropDownFilter'
import Loader from '../components/react-table-components/Loader'
import CellWrapper from '../components/react-table-components/CellWrapper'
import TableHeader from "./TableHeader";
import {filterMethod,configComplianceMap} from "../utils/utils";
import _ from "lodash";

const ConfigServiceTable = ({getTableData,isTableLoaded,tableData,updateTime,error,selectedOrg}) => {
 getTableData(isTableLoaded);
 if(isTableLoaded) {
  if(error){
   return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
  }
  if(!selectedOrg){
   return <span>Sorry you don't have accounts to monitor</span>
  }
  const columns=[
   {
    Header:"Rules",
    headerClassName: styles['maintenance-table-header'],
    columns: [
     {
      headerStyle: {
       overflow: "visible"
      },
      Header: "Account",
      accessor: "account",
      width:233,
      Cell: cell => CellWrapper(cell.value),
      Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'account', filter, onChange }),
      filterMethod: filterMethod
     },
     {
      headerStyle: {
       overflow: "visible"
      },
      Header: "Region",
      accessor: "region",
      width:150,
      Cell: cell => CellWrapper(cell.value),
      Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'region', filter, onChange }),
      filterMethod: filterMethod
     },
     {
      Header: "Config Rule Name",
      accessor: "configRuleName",
      width:527,
      Cell: cell => CellWrapper(cell.value)
     },
     {
      headerStyle: {
       overflow: "visible"
      },
      Header: "Compliance",
      accessor: "compliance",
      width:187,
      Cell: cell => CellWrapper(configComplianceMap[cell.value]),
      Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'compliance', filter, onChange,labelMap:configComplianceMap }),
      filterMethod: filterMethod
     }
    ]
   }
  ];
  return <div>
   <TableHeader headerTitle={"Config Service"}/>
   <ReactTable
       data={tableData}
       columns={columns}
       defaultPageSize={tableData.length}
       minRows = {0}
       loading={!isTableLoaded}
       sortable = {true}
       filterable = {true}
       defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
       className={"-striped -highlight "+styles["maintenance-table"]}
       showPageSizeOptions = {false}
       showPagination = {false}
       style={{minHeight:500,width:1099,marginLeft:45}}/>
  </div>
 } else {
  return <Loader/>
 }
};

const mapStateToProps = state => {
 return {
  isTableLoaded: state.configServiceTable.isTableLoaded,
  tableData: state.configServiceTable.tableData,
  error:state.configServiceTable.error,
  updateTime:state.configServiceTable.updateTime,
  selectedOrg: state.organizations.selectedOrg
 };
};
const mapDispatchToProps = (dispatch) => {
 return {
  getTableData:(isTableLoaded)=> {
   if(!isTableLoaded)
    setTimeout(() => {
     dispatch({type: 'CSTABLE:LOAD-DATA'});
    })
  }
 };
};
export default connect(mapStateToProps,mapDispatchToProps)(ConfigServiceTable);