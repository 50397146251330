import React from 'react'
import {connect} from "react-redux";
import styles from './components.module.scss'
import "./TableStyle.scss"
import ReactTable from "react-table";
import DropDownFilter from './react-table-components/DropDownFilter'
import Loader from '../components/react-table-components/Loader'
import CellWrapper from '../components/react-table-components/CellWrapper'
import {onClickRow, filterMethod} from "../utils/utils";
import _ from "lodash";
import TableHeader from "./TableHeader";

const SubTable = ({row,tableData}) => {
    const dataRow = tableData.data.find(dataRow => dataRow.InstancesId === row.original.InstancesId).MoreInfo;
    if(!dataRow)
        return;
    const subColumnsConst = [
        {
            Header: "Volume ID",
            accessor: "VolumeId",
            Cell: cell => CellWrapper(cell.value)
        },
        {
            Header: "Size",
            accessor: "Size",
            Cell: cell => CellWrapper(cell.value+"GB")
        },
        {
            Header: "Iops",
            accessor: "Iops",
            Cell: cell => CellWrapper(cell.value)
        },
        {
            Header: "Volume Type",
            accessor: "Type",
            Cell: cell => CellWrapper(cell.value)
        }
    ];
    return (
        <div style={{ padding:"25px 0 15px 15px",marginLeft:"35px",marginBottom:"35px",overflow:"hidden" }}>
            <ReactTable
                defaultPageSize={dataRow.length}
                data= {dataRow}
                columns={subColumnsConst}
                showPagination = {false}
                showPageSizeOptions = {false}
                filterable = {true}
                defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                minRows = {0}
            />
        </div>
    )
};

const EC2InstancesTable = ({getTableData,isTableLoaded,tableData,error,selectedOrg}) => {
    getTableData(isTableLoaded);
    if(isTableLoaded) {
        if(error){
            return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        if(tableData.data.length < 2){
            return <span>No data to display</span>
        }
        const columns=[
            {
                Header:"Instances",
                headerClassName: styles['maintenance-table-header'],
                columns: [
                    {
                        expander: true,
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Account",
                        accessor: "Account",
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData.data, fieldName:'Account', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "State",
                        accessor: "State",
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData.data, fieldName:'State', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Region",
                        accessor: "Region",
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData.data, fieldName:'Region', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        Header: "Instances Name",
                        accessor: "InstancesName",
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        Header: "Instances Owner",
                        accessor: "InstancesOwner",
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        Header: "Instance Type",
                        accessor: "InstanceType",
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        Header: "Instances Id",
                        accessor: "InstancesId",
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        Header: "Tags",
                        accessor: "Tags",
                        Cell: cell => CellWrapper(cell.value)
                    }
                ]
            }
        ];
        return <div>
            <TableHeader headerTitle={"Instances Status"}/>
            <ReactTable
                data={tableData.data}
                columns={columns}
                defaultPageSize={tableData.data.length}
                minRows = {0}
                loading={!isTableLoaded}
                sortable = {true}
                filterable = {true}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                className={"-striped -highlight "+styles["maintenance-table"]}
                showPageSizeOptions = {false}
                showPagination = {false}
                getTdProps={onClickRow}
                SubComponent={row => SubTable({row:row,tableData:tableData})}
                style={{minHeight:500}}/>
        </div>
    } else {
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isTableLoaded: state.ec2Table.isTableLoaded,
        tableData: state.ec2Table.tableData,
        error:state.ec2Table.error,
        selectedOrg: state.organizations.selectedOrg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTableData:(isTableLoaded)=> {
            if(!isTableLoaded)
                setTimeout(() => {
                    dispatch({type: 'EC2TABLE:LOAD-DATA'});
                })

        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(EC2InstancesTable);