
const defaultState = () => ({
    error: null,
    isTableLoaded: false,
    tableData: null});

export default (state = defaultState(), {payload, type}) => {
    switch (type){
        case "EC2TABLE:LOAD-DATA-SUCCESS": {
            return {...state,isTableLoaded:true,error:false,tableData:payload}
        }
        case "EC2TABLE:LOAD-DATA-FAILURE": {
            return {...state,isTableLoaded:true,error:payload.response.data.message||true}
        }
        case "ORGANIZATIONS:CHANGEORG": {
            return {...state,isTableLoaded:false}
        }
        default:
            break;
    }
    return state;
}
