import axios from "axios";

export default ({getState,dispatch}) => next => action => {
    if (action.type === "AZUREADVISOR:LOAD-DATA") {
        axios.get("/" + getState().organizations.selectedOrg + "/azure-advisor-table")
            .then(
                ({data}) => {
                    dispatch({
                        type:"AZUREADVISOR:LOAD-DATA-SUCCESS",
                        payload:data
                    });
                }
            )
            .catch((err) => {
                    dispatch({
                        type:"AZUREADVISOR:LOAD-DATA-FAILURE",
                        payload:err
                    });
                }
            )
    }

    return next(action);
}