import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers'
import authMiddleware from "./middlewares/authMiddleware"
import costMapMiddleware from "./middlewares/costMapMiddleware"
import costChartMiddleware from "./middlewares/costChartMiddleware"
import costTableAccountsMiddleware from "./middlewares/costTableAccountsMiddleware"
import costTableRegionsMiddleware from "./middlewares/costTableRegionsMiddleware"
import ec2InstancesTableMiddleware from "./middlewares/ec2InstancesTableMiddleware.js"
import guardDutyMiddleware from "./middlewares/guardDutyMiddleware.js"
import costTableRegionsAzureMiddleware from "./middlewares/costTableRegionsAzureMiddleware.js"
import organizationsMiddleware from "./middlewares/organizationsMiddleware.js"
import azureAdvisorTableMiddleware from "./middlewares/azureAdvisorTableMiddleware.js"
import configServiceMiddleware from './middlewares/configServiceMiddleware'

export const history = createBrowserHistory();

const initialState = {};

const middleware = [
    authMiddleware,
    costMapMiddleware,
    costChartMiddleware,
    costTableAccountsMiddleware,
    costTableRegionsMiddleware,
    ec2InstancesTableMiddleware,
    guardDutyMiddleware,
    costTableRegionsAzureMiddleware,
    organizationsMiddleware,
    azureAdvisorTableMiddleware,
    configServiceMiddleware
];

const composedEnhancers = compose(
    applyMiddleware(...middleware)
);

const store = createStore(
    createRootReducer(),
    initialState,
    composedEnhancers
);

export default store