import axios from "axios";

export default ({getState,dispatch}) => next => action => {
    if (action.type === "CHART:LOAD-DATA") {
        axios.get("/"+getState().organizations.selectedOrg+"/accounts-cost")
            .then(
                (result) => {
                    dispatch({
                        type:"CHART:RENDER-CHART-SUCCESS",
                        payload:result.data
                    });

                }
            )
            .catch((err) => {
                    dispatch({
                        type:"CHART:RENDER-CHART-FAILURE",
                        payload:err
                    });
                }
            )
    }

    return next(action);
}