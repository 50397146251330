
const defaultState = () => (
    {error: null,
    isMapLoaded: false,
    mapData: null});

export default (state = defaultState(), {payload, type}) => {
    switch (type){
        case "MAP:RENDER-MAP-SUCCESS": {
            return {...state,isMapLoaded:true,error:false,mapData:payload}
        }
        case "MAP:RENDER-MAP-FAILURE": {
            return {...state,isMapLoaded:true,error:payload.response.data.message||true}
        }
        case "ORGANIZATIONS:CHANGEORG": {
            return {...state,isMapLoaded:false}
        }
        default:
            break;
    }
    return state;
}
