import React from 'react'
import {connect} from "react-redux";
import styles from './components.module.scss'
import "./TableStyle.scss"
import ReactTable from "react-table";
import DropDownFilter from './react-table-components/DropDownFilter'
import Loader from '../components/react-table-components/Loader'
import CellWrapper from '../components/react-table-components/CellWrapper'
import {onClickRow,filterMethod} from "../utils/utils";
import _ from 'lodash'
import TableHeader from "./TableHeader";

const SubTable = ({row,tableData}) => {
    const dataRow = tableData.find(dataRow => dataRow.Category === row.original.Category && dataRow.Problem === row.original.Problem && dataRow.LastUpdated === row.original.LastUpdated);
    if(!dataRow)
        return;

    return (
        <div style={{padding:"5px 10px 20px 20px"}}>
            <h2 style={{fontSize: "1.5em"}}>Solution:</h2>
            <span>{dataRow.Solution}</span>
            <br/>
            {dataRow.ImpactedValue && dataRow.ImpactedValue.length > 0 && (<span>ImpactedValue: {dataRow.ImpactedValue.join('')}</span>)}
        </div>
    )
};

const AzureAdvisorTable = ({getTableData,isTableLoaded,tableData,error,selectedOrg}) => {
    getTableData(isTableLoaded);
    if(isTableLoaded) {
        if(error){
            return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        const columns=[
            {
                Header:"Advises",
                headerClassName: styles['maintenance-table-header'],
                columns: [
                    {
                        expander: true,
                        width:30
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Subscription",
                        accessor: "Subscription",
                        width:200,
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData.data, fieldName:'Subscription', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Category",
                        accessor: "Category",
                        width:200,
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData.data, fieldName:'Category', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        Header: "Updated At",
                        accessor: "LastUpdated",
                        width:200,
                        Cell: cell => CellWrapper(new Date(cell.value).toLocaleString('en-GB'))
                    },
                    {
                        Header: "Problem",
                        accessor: "Problem",
                        Cell: cell => CellWrapper(cell.value)
                    }
                ]
            }
        ];
        return <div>
            <TableHeader headerTitle={"Azure Advisor"}/>
            <ReactTable
                data={tableData.data}
                columns={columns}
                defaultPageSize={tableData.data.length}
                minRows = {0}
                loading={!isTableLoaded}
                sortable = {true}
                filterable = {true}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                className={"-striped -highlight "+styles["maintenance-table"]}
                showPageSizeOptions = {false}
                showPagination = {false}
                getTdProps={onClickRow}
                SubComponent={row => SubTable({row:row,tableData:tableData.data})}
                style={{minHeight:500}}/>
        </div>
    } else {
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isTableLoaded: state.azureAdvisorTable.isTableLoaded,
        tableData: state.azureAdvisorTable.tableData,
        error:state.azureAdvisorTable.error,
        selectedOrg: state.organizations.selectedOrg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTableData:(isTableLoaded)=> {
            if(!isTableLoaded)
                setTimeout(() => {
                    dispatch({type: 'AZUREADVISOR:LOAD-DATA'});
                })
        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(AzureAdvisorTable);