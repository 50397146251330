import React from 'react'
import {connect} from "react-redux";
import styles from './components.module.scss'
import "./TableStyle.scss"
import ReactTable from "react-table";
import DropDownFilter from './react-table-components/DropDownFilter'
import Loader from '../components/react-table-components/Loader'
import CellWrapper from '../components/react-table-components/CellWrapper'
import TableHeader from "./TableHeader";
import {onClickRow, filterMethod} from "../utils/utils";
import _ from "lodash";

const SubTable = ({row,tableData}) => {
    const dataRow = tableData.find(dataRow => dataRow.Account === row.original.Account && dataRow.Region === row.original.Region && dataRow.Title === row.original.Title);
    if(!dataRow)
        return;

    return (
        <div style={{padding:"5px 10px 20px 20px"}}>
            <h2 style={{fontSize: "1.5em"}}>Description:</h2>
            <span>{dataRow.Description}</span>
            <br/>
            {dataRow.Port && (<span>Port: {dataRow.Port}</span>)}
            <br/>
            {dataRow.PortName && (<span>Port Name: {dataRow.PortName}</span>)}
        </div>
    )
};

const GuardDutyTable = ({getTableData,isTableLoaded,tableData,updateTime,error,selectedOrg}) => {
    getTableData(isTableLoaded);
    if(isTableLoaded) {
        if(error){
            return <span>Failed to get table{_.isString(error)?": "+error:""}</span>
        }
        if(!selectedOrg){
            return <span>Sorry you don't have accounts to monitor</span>
        }
        const columns=[
            {
                Header:"Findings",
                headerClassName: styles['maintenance-table-header'],
                columns: [
                    {
                        expander: true
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Account",
                        accessor: "Account",
                        width:233,
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'Account', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Region",
                        accessor: "Region",
                        width:150,
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'Region', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        Header: "Created At",
                        accessor: "CreatedAt",
                        width:165,
                        Cell: cell => CellWrapper(new Date(cell.value).toLocaleString('en-GB'))
                    },
                    {
                        Header: "Title",
                        accessor: "Title",
                        width:527,
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Type",
                        accessor: "Type",
                        width:250,
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'Type', filter, onChange }),
                        filterMethod: filterMethod
                    },
                    {
                        Header: "Instances Id",
                        accessor: "instanceId",
                        width:175,
                        Cell: cell => CellWrapper(cell.value)
                    },
                    {
                        headerStyle: {
                            overflow: "visible"
                        },
                        Header: "Instances State",
                        accessor: "instanceState",
                        Cell: cell => CellWrapper(cell.value),
                        Filter: ({ filter, onChange }) => DropDownFilter({data:tableData, fieldName:'instanceState', filter, onChange }),
                        filterMethod: filterMethod
                    }
                ]
            }
        ];
        return <div>
            <TableHeader headerTitle={"Guard Duty"}/>
            <ReactTable
                data={tableData}
                columns={columns}
                defaultPageSize={tableData.length}
                minRows = {0}
                loading={!isTableLoaded}
                sortable = {true}
                filterable = {true}
                defaultFilterMethod={(filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                className={"-striped -highlight "+styles["maintenance-table"]}
                showPageSizeOptions = {false}
                showPagination = {false}
                getTdProps={onClickRow}
                SubComponent={row => SubTable({row:row,tableData:tableData})}
                style={{minHeight:500}}/>
        </div>
    } else {
        return <Loader/>
    }
};

const mapStateToProps = state => {
    return {
        isTableLoaded: state.gdTable.isTableLoaded,
        tableData: state.gdTable.tableData,
        error:state.gdTable.error,
        updateTime:state.gdTable.updateTime,
        selectedOrg: state.organizations.selectedOrg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTableData:(isTableLoaded)=> {
            if(!isTableLoaded)
                setTimeout(() => {
                    dispatch({type: 'GDTABLE:LOAD-DATA'});
                })
        }
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(GuardDutyTable);