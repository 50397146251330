import axios from "axios";

export default ({getState,dispatch}) => next => action => {
	if (action.type === "CSTABLE:LOAD-DATA") {
		axios.get("/" + getState().organizations.selectedOrg + "/config-service")
			.then(
				({data}) => {
					dispatch({
						type:"CSTABLE:LOAD-DATA-SUCCESS",
						payload:data
					});
				}
			)
			.catch((err) => {
					dispatch({
						type:"CSTABLE:LOAD-DATA-FAILURE",
						payload:err
					});
				}
			)
	}

	return next(action);
}