import axios from "axios";
import {createCanvas, loadImage} from "canvas";
import image from "./../images/map.png"
import green from "./../images/Green.png"
import yellow from "./../images/Yellow.png"
import red from "./../images/Red.png"
import _ from 'lodash'
const mapMarkers = [green,yellow,red];

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
}
export default ({getState,dispatch}) => next => action => {
    if (action.type === "MAP:LOAD-DATA") {
        const canvas = createCanvas(1300, 600);
        return axios.get("/"+getState().organizations.selectedOrg+"/regions-cost")
            .then(
                (result) => {
                    const ctx = canvas.getContext('2d');
                    ctx.font = '12px "Calibri"';
                    return fetch(image).then((response) => {
                        return response.arrayBuffer().then( (buffer) => {
                            const base64Flag = 'data:image/jpeg;base64,';
                            const imageStr = arrayBufferToBase64(buffer);
                            return loadImage(base64Flag+imageStr).then((image) => {
                                ctx.drawImage(image, 0, 0, 1300, 600);
                                ctx.fillText("=========== Legends ===========", _.last(result.data.legends).x-25, _.last(result.data.legends).y-85);
                                const PromiseArr = [];
                                result.data.legends.forEach(function(elem,index){
                                    PromiseArr.push(fetch(mapMarkers[index]).then((response) => {
                                        return response.arrayBuffer().then( (buffer) => {
                                            const imageStr = arrayBufferToBase64(buffer);
                                            return loadImage(base64Flag+imageStr).then((image) => {
                                                return ctx.drawImage(image, elem.x-15-(index)*5, elem.y-15-(index)*30);
                                            })
                                        })
                                    }));
                                    ctx.fillText(isNaN(elem.from)?"No data":"$"+elem.from+" - $"+elem.to, elem.x+(index)*7, elem.y-5-index*24);
                                });
                                ctx.fillText("==============================", _.first(result.data.legends).x-25, _.first(result.data.legends).y+15);
                                result.data.noRegionData.forEach(function(elem,index){
                                    PromiseArr.push(fetch(green).then((response) => {
                                        return response.arrayBuffer().then( (buffer) => {
                                            const imageStr = arrayBufferToBase64(buffer);
                                            return loadImage(base64Flag+imageStr).then((image) => {
                                                return ctx.drawImage(image, elem.x-15, elem.y-12);
                                            })
                                        })
                                    }));
                                    ctx.fillText("$"+elem.amount+" - "+elem.originalName, elem.x, elem.y);
                                });
                                result.data.lowPriority.forEach(function(elem,index){
                                    PromiseArr.push(fetch(green).then((response) => {
                                        return response.arrayBuffer().then( (buffer) => {
                                            const imageStr = arrayBufferToBase64(buffer);
                                            return loadImage(base64Flag+imageStr).then((image) => {
                                                return ctx.drawImage(image, elem.x, elem.y);
                                            })
                                        })
                                    }));
                                    ctx.fillText("$"+elem.amount+" - "+elem.originalName, elem.x, elem.y);
                                });
                                result.data.mediumPriority.forEach(function(elem,index){
                                    PromiseArr.push(fetch(yellow).then((response) => {
                                        return response.arrayBuffer().then( (buffer) => {
                                            const imageStr = arrayBufferToBase64(buffer);
                                            return loadImage(base64Flag+imageStr).then((image) => {
                                                return ctx.drawImage(image, elem.x, elem.y);
                                            })
                                        })
                                    }));
                                    ctx.fillText("$"+elem.amount+" - "+elem.originalName, elem.x, elem.y);
                                });
                                result.data.highPriority.forEach(function(elem,index){
                                    PromiseArr.push(fetch(red).then((response) => {
                                        return response.arrayBuffer().then( (buffer) => {
                                            const imageStr = arrayBufferToBase64(buffer);
                                            return loadImage(base64Flag+imageStr).then((image) => {
                                                return ctx.drawImage(image, elem.x, elem.y);
                                            })
                                        })
                                    }));
                                    ctx.fillText("$"+elem.amount+" - "+elem.originalName, elem.x, elem.y);
                                });

                                return Promise.all(PromiseArr).then(()=>{
                                    dispatch({
                                        type: "MAP:RENDER-MAP-SUCCESS",
                                        payload: canvas.toDataURL()
                                    });
                                })
                            });
                        });
                    })
                }
            )
            .catch((err) => {
                    dispatch({
                        type:"MAP:RENDER-MAP-FAILURE",
                        payload:err
                    });
                }
            )

    }

    return next(action);
}